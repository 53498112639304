import { Injectable } from "@angular/core";
import { Coach } from "../classes/flow/session/impl/Coach";
import { User } from "../classes/flow/session/impl/User";
import { EmptyResult } from "../classes/system/Result";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class CoachService {
  constructor(private readonly graphqlService: GraphQLService) {}

  public async addCoach(coach: Coach): Promise<EmptyResult | null> {
    const result = await this.graphqlService.query(`mutation {
      addCoach(coach: {
        firstName: "${coach.firstName}",
        lastName: "${coach.lastName}",
        email: "${coach.email}"
      }) {
        messages {
          message
          type
          goToLink
          linkToGoTo
        }
      }
    }`);

    return result.data.addCoach?.messages?.length
      ? {
        messages: this.graphqlService.constructMessages(result.data.addCoach.messages),
      }
      : null;
  }
  
  /**
   * gets all active coaches
   * @returns all active coaches/ coaches where "active" is true
   */
  public async getActiveCoaches(): Promise<Coach[]> {
    const res = await this.graphqlService.query(`query {
      coaches {
        value(where: {active: {eq: true}}) {
          id
          email
          firstName
          lastName
          userId
          toegangDatumVanaf
          toegangDatumTM
          registered
          emailSubscription
          changes {
            lastChange {
              userId
              time
            }
            fullDetails{
              key
              value {
                userId
                time
              }
            }
          }
        }
        messages {
          message
        }
      }
    }`);

    return res.data.coaches.value?.map((coach: any) => {
      return new Coach({
        id: coach.id,
        userId: coach.userId,
        userEmail: coach.email,
        firstName: coach.firstName,
        lastName: coach.lastName,
        accessStartingDate: coach.toegangDatumVanaf,
        accessEndDate: coach.toegangDatumTM,
        emailSubscription: coach.emailSubscription,
        changes: this.graphqlService.createChangesObject(coach.changes),
      });
    });
  }


  /**
   * Check if user is a coach
   * @param user The user to look for
   * @returns The coach object or undefined
   */
  public async getCoachByUser(user: User): Promise<Coach> {
    const res = await this.graphqlService.query(
      `query {
        coaches {
          value(where: {userId: {eq: ${user.userId}}}) {
            id
            email
            firstName
            lastName
            userId
            toegangDatumVanaf
            toegangDatumTM
            registered
            emailSubscription
            changes {
              lastChange {
                userId
                time
              }
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages {
            message
          }
        }
      }`
    );

    return res.data.coaches.value?.map((coach: any) => {
      return new Coach({
        id: coach.id,
        userId: coach.userId,
        roles: user.roles,
        userEmail: coach.email,
        firstName: coach.firstName,
        lastName: coach.lastName,
        accessStartingDate: coach.toegangDatumVanaf,
        accessEndDate: coach.toegangDatumTM,
        emailSubscription: coach.emailSubscription,
        changes: this.graphqlService.createChangesObject(coach.changes),
      });
    })?.[0];
  }

  public async getAllCoaches(): Promise<Coach[]> {
    const res = await this.graphqlService.query(`
    query {
      coaches {
        messages {
          message
        }
        value {
          id,
          email,
          firstName,
          lastName,
          toegangDatumTM,
          registered,
          coachSpecialties {
            specialty {
              id,
              name
            }
          }
          changes {
            lastChange {
              userId
              time
            }
            fullDetails{
              key
              value {
                userId
                time
              }
            }
          }
        }
      }
    }
    `);
    return res.data.coaches.value?.map((coach: any) => {
      return new Coach({
        id: coach.id,
        userEmail: coach.email,
        firstName: coach.firstName,
        lastName: coach.lastName,
        accessEndDate: coach.toegangDatumTM,
        specialties: coach.coachSpecialties.map((cosp: any) => {
          return {
            id: cosp.specialty.id,
            name: cosp.specialty.name,
          };
        }),
      });
    });
  }
}
